import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import BackButton from '../../BackButton';
import CredCompareSVGIcon from '../../CredCompareSVGIcons';
import { useTextMapper } from '../../../hooks/useTextMapper';
import { CcTypography } from '../../CcTypography';
import Check24 from '../../Icons/24/Check24';

type Props = {
  step: number;
  steps: number;
  onGoBack?: () => void;
};

const useStepsStyles = makeStyles(() => ({
  step: {
    position: 'relative',
    margin: '0 0 40px 0',
    color: 'rgba(9, 2, 36, 0.30)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: grey[100],
    border: '1px solid rgba(9, 2, 36, 0.10)',
    width: '32px',
    height: '32px',
    borderRadius: 16,

    '&:not(:last-child)::after': {
      top: 29,
      left: '49%',
      borderLeft: '1px solid rgba(9, 2, 36, 0.10)',
      height: 42,
      content: '""',
      position: 'absolute',
    },
  },
  activeStep: {
    color: 'white',
    background: 'rgba(40, 0, 240, 1)',
    boxShadow: '0px 0px 0px 4px rgba(40, 0, 240, 0.08), 0px 0px 0px 8px rgba(40, 0, 240, 0.04)',
    border: '2px solid rgba(9, 2, 36, 0.10)',
  },
  completedStep: {
    background: 'rgba(40, 0, 240, 1)',
    border: '2px solid rgba(9, 2, 36, 0.10)',
    '& svg': {
      width: 21,
      height: 21,
      transform: 'translate(0px, 1px)',
    },
    '&:not(:last-child)::after': {
      borderLeft: '1px solid rgba(40, 0, 240, 1)',
    },
  },
}));

const SidebarSteps = ({ steps, step }: Omit<Props, 'onGoBack'>) => {
  const stepClass = useStepsStyles();
  const stepsArr = Array.from({ length: steps }).map((_, i) => i + 1);

  return (
    <>
      {stepsArr.map((_step: number) => {
        return (
          <div
            key={_step}
            className={cx(
              stepClass.step,
              _step === step && stepClass.activeStep,
              _step < step && stepClass.completedStep,
            )}
          >
            {
              _step < step
                ? <Check24 color="white" />
                : (
                  <CcTypography
                    fontWeight={700}
                    variant="overline"
                    customColor="inherit"
                  >
                    {_step}
                  </CcTypography>
                )
            }
          </div>
        );
      })}
    </>
  );
};

export const ProgressComponent = ({
  steps,
  step,
  onGoBack,
}: Props) => {
  const getText = useTextMapper();

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'min-content',
    }}
    >
      <nav style={{ display: 'block', width: 'min-content' }}>
        <SidebarSteps
          steps={steps}
          step={step}
        />
      </nav>
      {
        onGoBack && (
          <BackButton goBack={onGoBack}>
            {getText('sidebar.back')}
          </BackButton>
        )
      }
    </div>
  );
};
